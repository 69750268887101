<script>
  import Card from './Card.svelte';

  export let onTurn;
  export let cards;
  export let showAll = false;
</script>

<style>
  .board {
    padding: 10px;
    display: grid;
    grid-template: repeat(5, 1fr) / repeat(5, 1fr);
    background-color: transparent;
    gap: 1vw;
    height: 100%;
    width: 100%;
  }
  @media screen and (max-width: 1000px) {
    .board{
      gap: 0px;
    }
  }

  .grid-item {
    background-color: lightblue;
    border-radius: 10px;
  }
</style>

<div class='board'>
  {#each cards as card, i}
    <Card on:click={onTurn(i)}
          content={card.word}
          found={card.found}
          show={showAll}
          type={card.type} />
  {/each}
</div>
