<style>
  .border {
    width: 100%;
    height: 100%;
    border: 2vw solid var(--border-color);
    padding: 2vw;
    transition: border-color 1s;
    overflow-y: scroll;
    background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .border::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .border {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
</style>

<script>
  let class_name = '';
  export { class_name as class };
  export let team;
</script>

<div class="border {class_name}" style="--border-color: var(--{team}-team)">
  <slot />
</div>
