<script>
  import { Modal, Game, GameBoard } from '/components';
  import { team } from '/stores/local';
  import { game } from '/stores/data';

  export let gameId;

  let showModal = false;
  let selectedCard = null;
  const cardAction = (index) => {
    if ($team === $game.currentTeam) {
      showModal = true;
      selectedCard = index;
    }
  }

  const cancelModal = () => {
    showModal = false;
  }

  const selectCard = () => {
    game.chooseCard(selectedCard);
    selectedCard = null;
    showModal = false;
  }
</script>

<style>
  .selection {
    text-align: center;
    font-weight: bold;
    color: white;
    font-size: 6vw;
    padding: 25px;
  }
</style>

<Modal showModal={showModal}>
  {#if selectedCard !== null}
    <div class="field">
      <h1 class='selection'>{$game.cards[selectedCard].word}</h1>
    </div>
    <div class="field">
      <button
        on:click={selectCard}
        class='button is-fullwidth'>
        Select
      </button>
    </div>
    <div class="field">
      <button
        class='button is-fullwidth'
        on:click={cancelModal}>
        Cancel
      </button>
    </div>
  {/if}
</Modal>
<Game gameId={gameId}>
  <span slot='board'>
    <GameBoard
      cards={$game.cards}
      onTurn={cardAction}
      />
  </span>
  <span slot='extras'>
    {#if $team === $game.currentTeam}
      <button
        class='button is-fullwidth'
        on:click={() => game.switchTeam()}>
        Finish Turn
      </button>
    {/if}
  </span>
</Game>
