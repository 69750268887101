<style>
  .tag {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    color: gray;
  }

  .code {
    font-size: 2rem;
    font-weight: bold;
  }

  .header {
    font-size: 1rem;
  }
</style>

<script>
  export let code;
</script>

<div class="tag">
  <p class="header">Game Code</p>
  <p class="code">{code}</p>
</div>
