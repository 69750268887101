<style>
  h1 {
    text-align: center;
    font-weight: bold;
  }

  .winner {
    font-size: 5vw;
    color: white;
  }

  .blue {
    font-size: 10vw;
    color: cornflowerblue;
    text-transform: capitalize;
  }

  .red {
    font-size: 10vw;
    color: crimson;
    text-transform: capitalize;
  }
</style>

<script>
  import Modal from './Modal.svelte';
  import { roles } from '/util/codenames';
  export let winner = null;
</script>

<Modal showModal={winner}>
    <h1 class='winner'>Winner:</h1>
    <h1 class:blue={winner === roles.BLUE}
        class:red={winner === roles.RED}>
      {winner}
    </h1>
    <slot></slot>
</Modal>

