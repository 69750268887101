<script>
  import { team } from '/stores/local';
  import { Modal } from '/components';
  import { roles } from '/util/codenames';

  let showModal;

  $: showModal = !$team;

  const chooseOperative = async () => {
    showModal = true;
  }

  const chooseTeam = (teamString) => {
    team.set(teamString);
  }
</script>

<style>
  .selection {
    text-align: center;
    font-weight: bold;
    color: white;
    font-size: 6vw;
    padding: 25px;
  }
</style>

<slot></slot>
<Modal showModal={showModal}>
  <div class="selection field">
    <h1>
      Choose Team
    </h1>
  </div>
  <div class='field'>
    <button
      on:click={() => chooseTeam(roles.RED)}
      class='button is-fullwidth'>
      Red Team
    </button>
  </div>
  <div class='field'>
    <button
      class='button is-fullwidth'
      on:click={() => chooseTeam(roles.BLUE)}>
      Blue Team
    </button>
  </div>
</Modal>
