<style>
  .form {
    margin: auto;
    padding-top: 10%;
  }

  @media screen and (min-width: 500px) {
    .form {
      width: 500px;
    }
  }

  @media screen and (max-width: 500px) {
    .form {
      width: 90%;
    }
  }
</style>

<script>
  import { goto } from '@sveltech/routify';
  import { game } from '/stores/data';

  export let gameId;

  const goToBoardView = async () => {
    $goto(`/game/board/${gameId}`);
  };

  const goToSpymasterView = async () => {
    $goto(`/game/spymaster/${gameId}`);
  };

  const goToOperativeView = async () => {
    $goto(`/game/operative/${gameId}`);
  };
</script>

<div class="form">
  <div class="field">
    <h1 class="title is-1 has-text-centered">Choose View</h1>
  </div>
  <div class="field">
    <button class="button is-fullwidth" on:click={goToBoardView}>Board</button>
  </div>
  <div class="field">
    <button class="button is-fullwidth" on:click={goToSpymasterView}>
      Spymaster
    </button>
  </div>
  <div class="field">
    <button class="button is-fullwidth" on:click={goToOperativeView}>
      Operative
    </button>
  </div>
</div>
