<style>
  .form {
    margin: auto;
    padding-top: 10%;
  }

  @media screen and (min-width: 500px) {
    .form {
      width: 500px;
    }
  }

  @media screen and (max-width: 500px) {
    .form {
      width: 90%;
    }
  }

  .uppercase {
    text-transform: uppercase;
  }
</style>

<script>
  import { game } from '/stores/data';
  import { goto } from '@sveltech/routify';

  let existingGameCode;

  const joinGame = () => {
    $goto(`/view/${existingGameCode.toUpperCase()}`);
  };

  const makeGame = () => {
    const code = game.new();
    $goto(`/view/${code}`);
  };
</script>

<div class="form">
  <div class="field">
    <h1 class="title is-1 has-text-centered">Codenames</h1>
  </div>
  <div class="field">
    <button class="button is-fullwidth" on:click={makeGame}>Create Game</button>
  </div>

  <div class="field has-addons">
    <div class="control is-expanded">
      <input
        bind:value={existingGameCode}
        class="input"
        type="text"
        size="4"
        maxlength="4"
        style="text-transform:uppercase"
        placeholder="Game Code" />
    </div>
    <div class="control">
      <button
        on:click={joinGame}
        class="button is-info"
        style="text-transform:uppercase">
        Join Game
      </button>
    </div>
  </div>
</div>
