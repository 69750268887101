<script>
  import IdTag from './IdTag.svelte';
  import TeamBorder from './TeamBorder.svelte';
  import WinnerNotification from './WinnerNotification.svelte';
  import { game } from '/stores/data';
  import { onMount, onDestroy } from 'svelte';
  import { goto } from '@sveltech/routify';

  export let gameId;

  onMount(() => {
    game.listen(gameId);
  })

  $: if (!$game.loading && !$game.started && !$game.dead) {
    game.start();
  }

  onDestroy(() => {
    game.stopListening();
  });

  const playAgain = () => {
    game.reset();
    game.start();
  }
</script>

<style>
  .grid-container {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1.7fr 0.3fr 7fr 0.3fr 1.7fr 1fr;
    grid-template-rows: 10% 1fr 80px;
    gap: 1px 1px;
    grid-template-areas: ". . . Tag . . ."
      ". Board Board Board Board Board ."
      ". . . Extras . . .";
  }

  @media screen and (max-width: 1000px) {
    .grid-container {
      grid-template-areas: ". . . Tag . . ."
        "Board Board Board Board Board Board Board"
        ". . . Extras . . .";
        grid-template-rows: .25fr 1fr .5fr;
    }
  }

  .Board {
    grid-area: Board;
  }

  .Tag {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-area: Tag;
  }

  .Extras {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-area: Extras;
  }
</style>

{#if $game.loading}
  <p> Loading... </p>
{:else if $game.dead}
  <p> Game is Dead </p>
{:else}
  <TeamBorder team={$game.currentTeam}>
    <div class="grid-container">
      <div class='Extras'>
        <slot name='extras'></slot>
      </div>
      <div class="Tag">
        <IdTag code={gameId} />
      </div>
      <div class="Board">
        <slot name='board'></slot>
      </div>
    </div>
  </TeamBorder>
  <WinnerNotification winner={$game.winner}>
    <button class='button'
            on:click={playAgain}
            >
            Play Again
    </button>
  </WinnerNotification>
{/if}
