<style>
  .modal {
    display: flex;
    justify-content: start;
    position: fixed; /* Stay in place */
    z-index: -1; /* Sit on top */
    padding-top: 10%; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    opacity: 0%;
    background-color: transparent; /* Fallback color */
    transition: background-color 500ms;
    transition: opacity 500ms;
  }

  .showmodal {
    z-index: 999;
    opacity: 100%;
    background-color: rgba(0, 0, 0, 0.8);
  }

  .modal-content {
    display: flex;
    justify-content: start;
    flex-direction: column;
    padding: 10px;
  }
</style>

<script>
  export let showModal = null;
</script>

<div class="modal" class:showmodal={showModal}>
  <div class="modal-content">
    <slot />
  </div>
</div>
