<style>
  .card {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .card {
    width: 100%;
    height: 100%;
    font-size: 2vw;
    border: 1px solid lightgray;
    background-color: white;
    transition: background-color 100ms linear;
    word-break: break-all;
    padding: 5%;
  }

  .bystander {
    background-color: darkkhaki;
    color: white;
  }

  .red {
    background-color: crimson;
    color: white;
  }

  .blue {
    background-color: cornflowerblue;
    color: white;
  }

  .assassin {
    background-color: black;
    color: white;
  }

  .found {
    opacity: 0.5;
    text-decoration: line-through;
  }
</style>

<div class='card'
     class:bystander={foundOrShow && type == 'bystander'}
     class:red={foundOrShow && type == 'red'}
     class:assassin={foundOrShow && type == 'assassin'}
     class:blue={foundOrShow && type == 'blue'}
     class:found={found}
     on:click>
  <p> {content} </p>
</div>

<script>
  export let content;
  export let type = '';
  export let found = false;
  export let show = false;

  $: foundOrShow = found || show;
</script>
